<template>
  <div id="print" class="print-layout">
    <div style="margin-top: 5px"></div>
    <table align="center" class="tabel-border" style="width: 100%">
      <tbody>
        <tr>
          <td style="width: 311px; padding: 15px" class="text-center">
            <img src="../../assets/img/logo.png" style="width: 270px" />
          </td>
          <td class="text-center bold text-uppercase">
            <div class="mt-2">
              <p>REPORT</p>
              <p>{{ detail.type_ticket }} MAINTENANCE</p>
            </div>
          </td>
          <td style="width: 311px; padding: 15px" class="text-center">
            <img src="../../assets/img/indobara.png" style="width: 270px" />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <table align="center" class="tabel-border" style="width: 100%">
      <tbody>
        <tr>
          <td colspan="6" class="text-center bold text-uppercase">
            CHECK LIST {{ detail.type_ticket }} MAINTENANCE {{ nama_formchecklist }}
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <div style="display: flex">
              <div style="margin-left: 5px; width: 200px">Document Number</div>
              <div>: {{ detail.no_ticket }}</div>
            </div>
          </td>
          <td colspan="4">
            <div style="display: flex">
              <div style="margin-left: 5px; width: 200px">Time Start</div>
              <div>: {{ datetimeFormat(detail.ticket_created) }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <div style="display: flex">
              <div style="margin-left: 5px; width: 200px">Date</div>
              <div>: {{ dateFormat(detail.ticket_created) }}</div>
            </div>
          </td>
          <td colspan="4">
            <div style="display: flex">
              <div style="margin-left: 5px; width: 200px">Time End</div>
              <div>: {{ datetimeFormat(detail.ticket_closed) }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <div style="display: flex">
              <div style="margin-left: 5px; width: 200px">Area</div>
              <div>: {{ detail.nama_area }}</div>
            </div>
          </td>
          <td colspan="4">
            <div style="display: flex">
              <div style="margin-left: 5px; width: 200px">Duration</div>
              <div>
                :
                {{
                  calculateDuration(detail.ticket_created, detail.ticket_closed)
                }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <div style="display: flex">
              <div style="margin-left: 5px; width: 200px">Location</div>
              <div>: {{ detail.nama_lokasi }}</div>
            </div>
          </td>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td colspan="2">
            <div style="display: flex">
              <div style="margin-left: 5px; width: 200px">Sub Location</div>
              <div>: {{ detail.nama_sublokasi }}</div>
            </div>
          </td>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td colspan="2">
            <div style="display: flex">
              <div style="margin-left: 5px; width: 200px">Technician</div>
              <div>
                :
                <span>
                  {{
                    listTeknisi
                      .map((teknisi) => teknisi.nama_karyawan)
                      .join(", ")
                  }}
                </span>
              </div>
            </div>
          </td>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td colspan="2" rowspan="2" class="text-center bold">
            Item Maintenance
          </td>
          <td colspan="4" class="text-center bold">Check List</td>
        </tr>
        <tr>
          <td class="text-center bold">Status</td>
          <td class="text-center bold">Value</td>
          <td colspan="2" class="text-center bold">Image</td>
        </tr>
        <template v-for="(items, category, index) in groupedMaintenance">
          <tr :key="category">
            <td
              :rowspan="items.length + 1"
              style="width: 40px; vertical-align: top"
              class="text-center bold"
            >
              {{ index + 1 }}
            </td>
            <td colspan="3">
              <strong>{{ category }}</strong>
            </td>
            <td class="text-center bold">Before</td>
              <td class="text-center bold">After</td>
          </tr>
          <tr v-for="item in items" :key="item.id_maintenance">
            <td>- {{ item.item_maintenance }}</td>
            <td class="text-center">
              <span>
                <i class="fa fa-check"></i>
              </span>
            </td>
            <td class="text-center">{{ item.value }}</td>
            <td style="width: 100px">
              <a v-if="item.image_check_before" :href="item.image_check_before" target="_blank">
              <img
                :src="item.image_check_before"
                alt="image_check_before"
                style="width: 100px"
              />
            </a>
            </td>
            <td style="width: 100px">
              <a v-if="item.image_check" :href="item.image_check" target="_blank">
                <img
                v-if="item.image_check"
                :src="item.image_check"
                alt="image_checks"
                style="width: 100%"
              />
              </a>
            </td>
          </tr>
          
        </template>

        <tr>
          <td colspan="6" class="text-left" style="padding: 5px;">
          Note Koordinator : <b>{{detail.note_k}}</b>
            </td>
       </tr>
       <tr>
          <td colspan="6" class="text-left" style="padding: 5px;">
          Note Supervisor : <b>{{detail.note_s}}</b>
            </td>
       </tr>
            <tr>
          <td colspan="6" class="text-center" style="padding: 5px;">
          

          <table align="center" class="tabel-border" style="width: 100%">
      <tbody>
        <tr>
          <td class="text-center">
            Created By,
          </td>
          <td class="text-center">
            Checked By,
          </td>
          <td class="text-center">
            Verified By,
          </td>
          <td class="text-center">
            Finished By,
          </td>
         
        </tr>
        <tr>
          <td class="text-center ">
            <img src="../../assets/img/submitted.jpg" style="width: 150px;padding: 10px;" />
          </td>
          <td class="text-center ">
            <img src="../../assets/img/completed.jpg" style="width: 150px;padding: 10px;" />
          </td>
          <td class="text-center ">
            <img src="../../assets/img/completed.jpg" style="width: 150px;padding: 10px;" />
          </td>
          <td class="text-center ">
            <img src="../../assets/img/completed.jpg" style="width: 150px;padding: 10px;" />
          </td>
         
        </tr>
        <tr>
          <td class="text-center bold ">
            <span style="font-weight: 100;">{{detail.user}}</span><br>
            User<br>
            <span style="font-weight: 100;">{{detail.createdOn}}</span>
          </td>
          <td class="text-center bold ">
            <span style="font-weight: 100;">{{detail.engineer_name}}</span><br>
            Engineer<br>
            <span style="font-weight: 100;">{{detail.process_date}}</span>
          </td>
          <td class="text-center bold ">
            <span style="font-weight: 100;">{{detail.koordinator_name}}</span><br>

            Koordinator
            <br>
            <span style="font-weight: 100;">{{detail.verify_date}}</span>
          </td>
          <td class="text-center bold ">
            <span style="font-weight: 100;">{{detail.supervisor_name}}</span><br>

            Supervisor
            <br>
            <span style="font-weight: 100;">{{detail.finish_date}}</span>
          </td>
         
        </tr>
      </tbody>
    </table>

  </td>
          
        </tr>
      </tbody>
    </table>


  </div>
</template>

<script>
import PageOptions from "../../config/PageOptions.vue";
import axios from "axios";
import userData from "../../plugins/userData";
import moment from "moment";

export default {
  mixins: [userData],
  data() {
    return {
      detail: {
        no_ticket: "",
        ticket_created: "",
        subject_ticket: "",
        fill_ticket: "",
        nama_device: "",
        type_ticket: "",
        ticket_closed: "",
        nama_area: "",
        nama_lokasi: "",
        nama_sublokasi: "",
      },
      nama_formchecklist :"",
      listTeknisi: [],
      itemMaintenance: [],
      dataDipilih: [],
      loading: false,
      showUserAccess: true,
      valueQR: "",
      valueQRCust: "",
      sizeQR: 150,
      sizeQRCust: 75,
    };
  },
  created() {
    PageOptions.pageEmpty = true;
    document.body.className = "bg-white";
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.getDetail(id);
    }
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  computed: {
    groupedMaintenance() {
      return this.itemMaintenance.reduce((groups, item) => {
        const category = item.nama_cat_maintenance;
        if (!groups[category]) {
          groups[category] = [];
        }
        groups[category].push(item);
        return groups;
      }, {});
    },
  },
  methods: {
    getDetail(id) {
      this.isLoading = true;
      axios
        .get(this.$url+"/v2/report/detail/" + id, {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          for (let key in response.data.data) {
            this.detail[key] = response.data.data[key];
          }
          this.listTeknisi = response.data.data_teknisi;
          this.itemMaintenance = response.data.item_maintenance;
          if(this.itemMaintenance.length > 0){
            this.nama_formchecklist = this.itemMaintenance[0].nama_formchecklist;
          }
          // this.print();
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    printData() {
      this.print();
    },
    goBack() {
      this.$router.go(-1);
    },
    goPrint() {
      window.print();
    },
    indonesianFormat(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
    timeStampFormat(dateTime) {
      return moment.unix(dateTime).format("D MMMM YYYY");
    },
    dateFormat(dateTime) {
      if (dateTime) {
        return moment(dateTime).format("DD/MM/YYYY");
      } else {
        return "-";
      }
    },
    timeFormat(dateTime) {
      if (dateTime) {
        return moment(dateTime).format("H:m");
      } else {
        return "-";
      }
    },
    datetimeFormat(dateTime) {
      if (dateTime) {
        return moment(dateTime).format("DD/MM/YYYY HH:mm");
      } else {
        return "-";
      }
    },
    calculateDuration(start, end) {
      if (end) {
        const date1 = moment(start);
        const date2 = moment(end);

        const diff = moment.duration(date2.diff(date1));
        const totalHours = Math.floor(diff.asHours());
        const minutes = diff.minutes();

        return `${totalHours}:${minutes}`;
      } else {
        return "Not Finish";
      }
    },
    print () {
      let section = document.getElementById("print")
        section  = document.createElement("div")
        section.id = "print"
        document.body.appendChild(section)
      window.print();
    },
  },
};
</script>
<style scoped>
.cap-dlh {
  position: relative;
  height: 250px;
  margin-top: -200px;
}
p {
  line-height: 10px;
}
.print-layout {
  padding: 0.5rem;
  font-family: "Times";
  font-size: 1.5em;
}
.tabel-border {
  border: 1px solid black !important;
  border-collapse: collapse !important;
  width: 100%;
}
.tabel-border tr td {
  border: 1px solid black !important;
  padding: 0.1em 0.5em;
}
.logo-institusi {
  vertical-align: middle;
}
.teks-center {
  text-align: center;
}
.bold {
  font-weight: bold;
}
.d-flex {
  display: flex;
}
.push-right {
  float: right;
}
.underline {
  text-underline-position: under;
  text-decoration: underline;
}
.uppercase {
  text-transform: uppercase;
}
.w-10 {
  width: 10px;
}
.w-200 {
  width: 200px;
}
.w-400 {
  width: 400px;
}
.btn-float-bottom {
  width: 50px;
  height: 50px;
  line-height: 25px;
  text-align: center;
  color: white;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  position: fixed;
  right: 30px;
  bottom: 30px;
}

.btn-float-top {
  width: 50px;
  height: 50px;
  line-height: 25px;
  text-align: center;
  color: white;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  position: fixed;
  right: 30px;
  bottom: 90px;
}

@media print {
  .btn {
    display: none;
  }
  .table {
    width: 100%;
  }
}
</style>